import { Center, Heading } from '@chakra-ui/react';

export const HomePage = () => {
  return (
    <Center>
      <Heading>Welcome Admin!</Heading>
    </Center>
  );
};

export default HomePage;
